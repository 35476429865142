<template>
 <div v-if="isLoading">
    <div class="text-center text-danger custom-screen-center carga">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else class="row mb-2">
    <div class="col-sm-12">
      <div class="card text-left">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-2">
              <div v-if="reportes.length > 0">
                <img src="@/assets/images/e32.png"
                 @click="reporteExcel"
                 style="cursor: pointer"
                 alt="excel"
                 title="formato excel">
                <img src="@/assets/images/pdf32.png"
                 @click="reportePDF"
                 style="cursor: pointer"
                 alt="pdf"
                 title="formato pdf">
              </div>
            </div>
            <div class="col-sm-8">
              <div class="col-sm-12 text-center font-weight-bold"
              style="font-size: 1.626rem;height: 34px">
                VENTA DEL DIA
              </div>
              <div class="col-sm-12 text-center">
                Expresado en {{nombreMoneda}}
              </div>
            </div>
            <div class="col-2">
              <div class="justify-content-center">
                <img :src="getPathLogo" alt="Imagen Empresa" height="80px">
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-2 text-right font-weight-bold">Fecha:</div>
            <div class="col-sm-4">{{formatDate}}</div>
          </div>
          <div class="form-group row">
            <div class="col-sm-2 text-right font-weight-bold">Sucursal:</div>
            <div class="col-sm-4">{{nombreSucursal}}</div>
            <div class="col-sm-2 text-right font-weight-bold">Usuario:</div>
            <div class="col-sm-4">{{nombreUsuario}}</div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-12">
              <div class="table-responsive">
                <table class="table table-bordered text-center" style="width:1500px">
                  <thead>
                    <tr>
                      <th :colspan="8" class="colspan-white"></th>
                      <th class="th-custom-color" :colspan="5">Forma de Pago</th>
                      <th :colspan="2" class="colspan-white"></th>
                    </tr>
                    <tr>
                      <th class="th-custom-color" width="2%">N°</th>
                      <th class="th-custom-color" width="6%">Origen</th>
                      <th class="th-custom-color" width="3%">Estado</th>
                      <th class="th-custom-color" width="12%">N° F.</th>
                      <th class="th-custom-color" width="16%">Paciente</th>
                      <th class="th-custom-color" width="15%">Razon Social</th>
                      <th class="th-custom-color" width="6%">Nit</th>
                      <th class="th-custom-color" width="3%">Importe</th>
                      <th class="th-custom-color" width="3%">Desc</th>
                      <th class="th-custom-color" width="3%">Caja</th>
                      <th class="th-custom-color" width="3%">Banco</th>
                      <th class="th-custom-color" width="3%">Tarjeta</th>
                      <th class="th-custom-color" width="3%">Credito</th>
                      <th class="th-custom-color" width="3%">Ajuste</th>
                      <th class="th-custom-color" width="3%">Seguro</th>
                      <th class="th-custom-color" width="16%">Medico</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(reporte, index) in reportes" :key="index"
                      :class="reporte.estado==='Anulada'? 'bg-anulado':''"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ reporte.origen }}</td>
                      <td>{{ reporte.estado }}</td>
                      <td>{{ reporte.numero }}</td>
                      <td>{{ reporte.paciente }}</td>
                      <td>{{ reporte.nombre }}</td>
                      <td>{{ reporte.nit }}</td>
                      <td v-if="reporte.total !== 0 && reporte.total !== null">
                        {{ reporte.total | numeral('0,0.00') }}
                      </td>
                      <td v-else>-</td>
                      <td v-if="reporte.descuento_monto !== 0 && reporte.descuento_monto !== null">
                        {{ reporte.descuento_monto | numeral('0,0.00') }}
                      </td>
                      <td v-else>-</td>
                      <td v-if="reporte.caja !== 0 && reporte.caja !== null">
                        {{ reporte.caja | numeral('0,0.00') }}
                      </td>
                      <td v-else>-</td>
                      <td v-if="reporte.banco !== 0 && reporte.banco !== null">
                        {{ reporte.banco | numeral('0,0.00') }}
                      </td>
                      <td v-else>-</td>
                      <td v-if="reporte.tarjeta !== 0 && reporte.tarjeta !== null">
                        {{ reporte.tarjeta | numeral('0,0.00') }}
                      </td>
                      <td v-else>-</td>
                      <td v-if="reporte.credito !== 0 && reporte.credito !== null">
                        {{ reporte.credito | numeral('0,0.00') }}
                      </td>
                      <td v-else>-</td>
                      <td v-if="reporte.ajuste !== 0 && reporte.ajuste !== null">
                        {{ reporte.ajuste | numeral('0,0.00') }}
                      </td>
                      <td v-else>-</td>
                      <td v-if="parseFloat(reporte.seguro) !== 0 && reporte.seguro !== null">
                        {{ reporte.seguro | numeral('0,0.00') }}
                      </td>
                      <td v-else>-</td>
                      <td>{{ reporte.medico }}</td>
                    </tr>
                    <tr>
                      <th colspan="7" class="text-right colspan-white" style="border-right: 1px solid white">Total:</th>
                      <th class="th-custom-color border border-white">
                        {{ totalImporte | numeral('0,0.00') }}
                      </th>
                      <th class="th-custom-color border border-white">
                        {{ totalDescuento | numeral('0,0.00') }}
                      </th>
                      <th class="th-custom-color border border-white">
                        {{ totalCaja | numeral('0,0.00') }}
                      </th>
                      <th class="th-custom-color border border-white">
                        {{ totalBanco | numeral('0,0.00') }}
                      </th>
                      <th class="th-custom-color border border-white">
                        {{ totalTarjeta | numeral('0,0.00') }}
                      </th>
                      <th class="th-custom-color border border-white">
                        {{ totalCredito | numeral('0,0.00') }}
                      </th>
                      <th class="th-custom-color border border-white">
                        {{ totalAjuste | numeral('0,0.00') }}
                      </th>
                      <th class="th-custom-color border border-white">
                        {{ totalSeguro | numeral('0,0.00') }}
                      </th>
                      <th colspan="1" class="text-right colspan-white"></th>
                    </tr>
                    <tr>
                      <th colspan="9" class="text-right colspan-white">Total Ingreso:</th>
                      <th colspan="3" class="th-custom-color">
                        {{ totalEfectivo | numeral('0,0.00') }}
                      </th>
                      <th colspan="4" class="text-right colspan-white"></th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-sm-3" v-if="reporteCF > 0 && reporteSF > 0">
              <div class="row">
                <div class="col-sm-6 offset-sm-5 text-right">
                  Pagos en Efectivo
                </div>
              </div>
              <div class="row" v-if="reporteCF > 0">
                <div class="col-sm-6 pr-2 text-right">
                  Pagos C/F
                </div>
                <div class="col-sm-5 border border-dark text-center">
                  {{reporteCF | numeral('0,0.00')}}
                </div>
              </div>
              <div class="row" v-if="reporteSF > 0">
                <div class="col-sm-6 pr-2 text-right">
                  Pagos S/F
                </div>
                <div class="col-sm-5 border border-dark text-center">
                  {{reporteSF | numeral('0,0.00')}}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 pr-2 text-right">
                  Total Efectivo
                </div>
                <div class="col-sm-5 border border-dark text-center">
                  {{reporteT | numeral('0,0.00')}}
                </div>
              </div>
            </div>
            <div class="col-sm-10">
              <label class="float-right">{{fechaConsulta}}</label>
              <label class="float-right mr-2"><strong>Consulta:</strong></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import axios from '../../common/axiosERP';
import { util } from '../../../plugins/util';

export default {
  name: 'ReporteDelDia',
  data() {
    return {
      isLoading: true,
      hash: this.$route.params.hash,
      nombreMoneda: '',
      nombreSucursal: '',
      nombreUsuario: '',
      reportes: [],
      fechaConsulta: '',
      reporteCF: 0,
      reporteSF: 0,
      reporteT: 0,
    };
  },
  created() {
    this.cargarReporteApi();
  },
  methods: {
    decodeHash() {
      return JSON.parse(atob(this.hash));
    },
    async cargarReporteApi() {
      try {
        const REQUEST = {
          fecha: this.decodeHash().fecha,
          sucursal_id: this.decodeHash().sid,
          usuario_id: this.decodeHash().uid,
          moneda_id: this.decodeHash().mid,
        };
        const RESPONSE = await axios.get('/clinic/reporte/venta_dia/web', {
          params: {
            data: JSON.stringify(REQUEST),
          },
        });
        this.nombreMoneda = RESPONSE.data.data.moneda.name;
        this.nombreSucursal = RESPONSE.data.data.sucursal.nombre;
        this.nombreUsuario = RESPONSE.data.data.usuario.nombre;
        this.reportes = RESPONSE.data.data.detalles;
        this.fechaConsulta = RESPONSE.data.data.fecha_consulta;
        this.reporteCF = parseFloat(RESPONSE.data.data.reporte_cf);
        this.reporteSF = parseFloat(RESPONSE.data.data.reporte_sf);
        this.reporteT = parseFloat(RESPONSE.data.data.reporte_t);
        this.isLoading = false;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async reporteExcel() {
      try {
        this.isLoading = true;
        const REQUEST = {
          fecha: this.decodeHash().fecha,
          sucursal_id: this.decodeHash().sid,
          usuario_id: this.decodeHash().uid,
          moneda_id: this.decodeHash().mid,
        };
        const RESPONSE = await axios.get('/clinic/reporte/venta_dia/excel', {
          params: {
            data: JSON.stringify(REQUEST),
          },
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'data:application/vnd.ms-excel' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        const A = document.createElement('a');
        A.href = DOWNLOAD_URL;
        A.download = 'reporteInformeDelDia.xlsx';
        document.body.appendChild(A);
        A.click();
        document.body.removeChild(A);
        this.isLoading = false;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async reportePDF() {
      try {
        this.isLoading = true;
        const REQUEST = {
          fecha: this.decodeHash().fecha,
          sucursal_id: this.decodeHash().sid,
          usuario_id: this.decodeHash().uid,
          moneda_id: this.decodeHash().mid,
        };
        const RESPONSE = await axios.get('/clinic/reporte/venta_dia/pdf', {
          params: {
            data: JSON.stringify(REQUEST),
          },
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'application/pdf' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        window.open(DOWNLOAD_URL, '_blank');
        this.isLoading = false;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
  },
  computed: {
    ...mapGetters('main', ['getPathLogo']),
    formatDate() {
      const FECHA = JSON.parse(atob(this.hash)).fecha;
      const DIA = moment(FECHA, 'YYYY-MM-DD', 'es').format('dddd');
      const FECHA_FORMATEADA = moment(FECHA, 'YYYY-MM-DD').format('DD/MM/YYYY');
      return `${DIA.charAt(0).toUpperCase()}${DIA.slice(1)} ${FECHA_FORMATEADA}`;
    },
    totalImporte() {
      let total = 0;
      this.reportes.forEach((reporte) => {
        total += parseFloat(reporte.total);
      });
      return total;
    },
    totalDescuento() {
      let total = 0;
      this.reportes.forEach((reporte) => {
        total += parseFloat(reporte.descuento_monto);
      });
      return total;
    },
    totalCaja() {
      let total = 0;
      this.reportes.forEach((reporte) => {
        total += (reporte.caja) ? parseFloat(reporte.caja) : 0;
      });
      return total;
    },
    totalBanco() {
      let total = 0;
      this.reportes.forEach((reporte) => {
        total += reporte.banco ? parseFloat(reporte.banco) : 0;
      });
      return total;
    },
    totalTarjeta() {
      let total = 0;
      this.reportes.forEach((reporte) => {
        total += reporte.tarjeta ? parseFloat(reporte.tarjeta) : 0;
      });
      return total;
    },
    totalCredito() {
      let total = 0;
      this.reportes.forEach((reporte) => {
        total += reporte.credito ? parseFloat(reporte.credito) : 0;
      });
      return total;
    },
    totalAjuste() {
      let total = 0;
      this.reportes.forEach((reporte) => {
        total += reporte.ajuste ? parseFloat(reporte.ajuste) : 0;
      });
      return total;
    },
    totalSeguro() {
      let total = 0;
      this.reportes.forEach((reporte) => {
        total += reporte.seguro ? parseFloat(reporte.seguro) : 0;
      });
      return total;
    },
    totalEfectivo() {
      return this.totalCaja + this.totalBanco + this.totalTarjeta;
    },
  },
};
</script>

<style scoped>
.bg-anulado{
  background-color: rgba(255, 82, 82,0.7);
}
.carga{
  padding-top: 15%;
}
</style>
